<template>
    <div class="editor" :style="cssVars" v-bind:class="disabledClass">
        <PrismEditor :tab-size="tabSize" v-model="code" :highlight="highlighter" @input="changed" />
        <div v-if="helpText" class="d-flex flex-wrap flex-md-nowrap justify-content-between mt-auto align-items-end">
            <div class="editor-help" v-html="helpText"></div>
            <div class="helptext ml-autot">
                <b-button 
                    v-if="exampleButton && exampleButton.show"
                    class="editor-btn" 
                    variant="outline-primary"
                    @click="exampleButton.action"
                    :disabled="exampleButton.disabled"
                >
                    {{ exampleButton.label }}
                </b-button>
                <div class="helptext-content" v-if="exampleButton && exampleButton.disabledHelp && exampleButton.disabled">
                    {{ exampleButton.disabledHelp }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import Prism Editor
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import "prismjs/components/prism-python";
import 'prismjs/components/prism-css';
import 'prismjs/themes/prism-tomorrow.css'; // import syntax highlighting styles

export default {
    name: 'CodeEditor',
    components: {
        PrismEditor
    },
    props: {
        dataObject: {
            type: Object,
            default: () => {
                return {
                    language: 'js',
                    code: '',
                    minHeight: '300px',
                }
            }
        },
        enabled: {
            type: Boolean,
            default: true
        },
        tabSize: {
            type: [String,Number],
            default: 4
        },
        helpText: {
            type: String,
            default: '',
        },
        exampleButton: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            code: '',
        }
    },
    mounted() {
        this.code = this.dataObject.code;
    },
    computed:{
        cssVars() {
            return {
                '--min-height': this.dataObject.minHeight
            }
        },
        disabledClass(){
            if( !this.enabled ) return 'editor-disabled';
            return '';
        },
    },
    methods: {
        highlighter( code ) {
            return highlight( code, languages[ this.dataObject.language ] );
        },
        changed( value ) {
            this.$emit( 'codeEditor-input', value );
        }
    },
    watch: {
        code( newVal ) {
            this.dataObject.code = newVal;
        },
        'dataObject.code'( newVal ) {
            this.code = newVal;
        }
    }
}
</script>

<style lang="scss" scoped>

.editor-disabled{
    pointer-events:none;
}
.editor {
    background-color:#fff !important;
    min-height: var(--min-height);
    display: flex;
    flex-direction: column;

    ::v-deep {
        .prism-editor__textarea,
        .prism-editor__editor {
            min-height: var(--min-height);
        }
    }

    ::v-deep .editor-help {
        margin-top: auto;
        padding: 10px 10px 0;
        color: $dark;
        font-family: $font;
        font-size: 0.875rem;

        a {
            color: navy;

            &:hover {
                color: navy;
            }
        }
    }
    &-btn {
        margin-left: auto;
        margin-right: 10px;
        min-width: max-content;
    }
}
::v-deep .prism-editor__textarea {
    &:focus,
    &:hover {
        outline: none;
    }
}
</style>